import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Formik, Form, Field } from "formik";
import { CheckboxWithLabel, Select, TextField } from "formik-material-ui";
import * as Yup from "yup";
import PhoneInput from "components/custom/PhoneInput";
import { InputDetails } from "src/graphql/mutations/createEnquiry";
import useCourses from "components/hooks/useCourses";
import { pluck } from "lib/helpers";
import useConstantValues from "components/hooks/useConstantValues";
import useFormSubmit from "components/hooks/useFormSubmit";
// import Alert from "components/Alert";
import PhoneInputMinNumberMsg from "./PhoneInputMinNumberMsg";

const DropQuerySchema = Yup.object().shape({
  firstName: Yup.string().required("First Name field is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email field is required"),
  contactNumber: Yup.string()
    .typeError("That doesn't look like a phone number")
    .required("Phone field is required")
    .min(8, PhoneInputMinNumberMsg),
  service_id: Yup.array()
    .required("Please select a course")
    .min(1, "Please select a course"),
});

const DropQueryForm: React.FC<DropQueryFormProps> = ({ afterSubmit }) => {
  const { courses } = useCourses();
  const [whoWeAre] = useConstantValues("WHO_WE_ARE");
  const { handleFormSubmit } = useFormSubmit(afterSubmit);

  const formFields: InputDetails = {
    form_type_constant_value_id: "162",
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    service_id: [],
    mode_of_contact_constant_value_id: "169",
    message: "",
    whoAreWe: "",
    terms: false,
    enquiries_type: "COMMON FOOTER DROP QUERY",
  };

  return (
    <>
      <Formik
        initialValues={formFields}
        validationSchema={DropQuerySchema}
        onSubmit={handleFormSubmit}
      >
        {({ submitForm, isSubmitting, errors, touched, values }) => (
          <Form autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Field
                  component={TextField}
                  size="small"
                  name="firstName"
                  type="text"
                  label="First Name"
                  fullWidth
                  placeholder="Enter your first name"
                  error={Boolean(errors.firstName) && touched.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Field
                  component={TextField}
                  size="small"
                  name="lastName"
                  type="text"
                  label="Last Name"
                  fullWidth
                  placeholder="Enter your last name"
                  error={Boolean(errors.lastName) && touched.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Field
                  component={TextField}
                  size="small"
                  name="email"
                  type="email"
                  label="Email"
                  fullWidth
                  placeholder="Enter your email"
                  error={Boolean(errors.email) && touched.email}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Field
                  component={PhoneInput}
                  name="contactNumber"
                  label="Phone"
                  fullWidth
                  placeholder="Enter your phone"
                  error={errors.contactNumber && touched.contactNumber}
                  countryCodeEditable={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  size="small"
                  style={{ marginTop: 0 }}
                >
                  <InputLabel id="course-label">Select Course</InputLabel>
                  <Field
                    component={Select}
                    name="service_id"
                    labelId="course-label"
                    id="course-autowidth"
                    label="Select Course"
                    error={Boolean(errors.service_id) && touched.service_id}
                    multiple
                    renderValue={(selected) =>
                      pluck(
                        courses.filter(function (itm) {
                          return selected.indexOf(itm.id) > -1;
                        }),
                        "name"
                      ).join(", ")
                    }
                  >
                    <MenuItem value="">Select an course</MenuItem>
                    {courses.map((course) => (
                      <MenuItem key={course.id} value={course.id}>
                        <Checkbox
                          checked={values.service_id.indexOf(course.id) > -1}
                        />
                        <ListItemText primary={course.name} />
                      </MenuItem>
                    ))}
                  </Field>
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                    {errors.service_id ? errors.service_id : ""}
                  </p>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Field
                  component={TextField}
                  name="whoAreWe"
                  type="text"
                  label="Who are you"
                  fullWidth
                  size="small"
                  placeholder="Select an option"
                  error={Boolean(errors.whoAreWe) && touched.whoAreWe}
                  select
                >
                  <MenuItem value="">Select</MenuItem>
                  {whoWeAre.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field
                  component={TextField}
                  size="small"
                  type="text"
                  label="Message"
                  name="message"
                  fullWidth
                  multiline
                  minRows={2}
                  error={Boolean(errors.message) && touched.message}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field
                  component={CheckboxWithLabel}
                  name="terms"
                  type="checkbox"
                  Label={{
                    label: (
                      <Typography variant="caption">
                        I agree to be contacted in the most suitable manner (by
                        phone or email) in order to respond to my enquiry
                      </Typography>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  fullWidth
                  size="large"
                  style={{ marginTop: 16 }}
                  endIcon={isSubmitting && <CircularProgress size={27} />}
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DropQueryForm;
