import React from "react";
import { useRouter } from "next/router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import CorporateLearningSolutionCard from "../cards/CorporateLearningSolutionCard";
import SliderNavigation from "./SliderNavigation";
import useSliderNavigation from "components/hooks/useSliderNavigation";
import renderHTML from "lib/render-html";
import bgImg from "public/images/bg/corporate-learning-solutions.webp";
import { TOOL_BAR_HEIGHT } from "lib/settings";
import DropQueryForm from "../forms/DropQueryForm";
import useFormDialog from "components/hooks/useFormDialog";
import FormDialog from "../custom/FormDialog";
import { Link } from "react-scroll";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: `url(${bgImg.src})no-repeat`,
      backgroundSize: "cover",
      position: "relative",
    },
    heading: {
      "& span": {
        fontWeight: 500,
        display: "block",
        marginTop: theme.spacing(2),
      },
    },
    slider: {
      "& .slick-arrow": {
        top: "unset",
        bottom: theme.spacing(-2.625),
      },
      "& .slick-prev": {
        left: "17%",
      },
      "& .slick-next": {
        right: "42%",
      },
    },
    dots: {
      listStyle: "none",
      display: "flex!important",
      margin: 0,

      padding: 0,
      position: "absolute",
      right: "52%",
      "& li": {
        "& div": {
          cursor: "pointer",
          transition: "all .5s",
          width: 20,
          height: 7,
          borderRadius: 50,
          border: `1px solid ${theme.palette.common.white}`,
          marginLeft: 5,
        },
      },
      "& li.slick-active": {
        "& div": {
          width: 35,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
  })
);
const CorporateLearningSolutionSlider: React.FC<
  CorporateLearningSolutionSliderProps
> = ({ content }) => {
  const classes = useStyles();
  const { sliderRef, next, previous } = useSliderNavigation();

  const settings = {
    className: classes.slider,
    dotsClass: classes.dots,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    dots: true,
    // eslint-disable-next-line react/display-name
    customPaging: function (i) {
      return <div key={i + 1}></div>;
    },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [dropQuery, toggleDropQuery] = useFormDialog();

  const router = useRouter();
  const course_query = router.route;
  let check_path = "";

  if (course_query == "/[country]" || course_query == "/") {
    check_path = "/";
  } else {
    check_path = course_query;
  }

  const handlePopupClose = () => {
    if (dropQuery) {
      toggleDropQuery();
    } else {
      return;
    }
  };

  return (
    <Box className={classes.root} paddingY={[3, 4, 6, 8]} color="#fff">
      <Container className="position-relative">
        <Typography
          variant="h1"
          component="h3"
          gutterBottom
          className={classes.heading}
        >
          {renderHTML(content.heading)}
        </Typography>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            {/* 
            //@ts-ignore */}
            <Slider {...settings} ref={sliderRef}>
              {content.sliders
                .filter((x) => x.heading !== null)
                .map((item, index) => (
                  <CorporateLearningSolutionCard
                    key={`corporate-learning-solution-card-` + index + 1}
                    heading={item.heading}
                    sub_heading={item.sub_heading}
                    paragraph={item.paragraph}
                    count={index + 1}
                  />
                ))}
            </Slider>
            <Hidden mdUp>
              <SliderNavigation onNext={next} onPrevious={previous} />
            </Hidden>
            <Box pt={[2, 3, 4, 6]} textAlign="center">
              {check_path != "/" ? (
                <Button
                  component={Link}
                  color="secondary"
                  variant="contained"
                  size="large"
                  spy={true}
                  to="request-for-training-form-section"
                  offset={-TOOL_BAR_HEIGHT}
                  duration={500}
                >
                  Enquire Now
                </Button>
              ) : (
                <>
                  <Button
                    component={Link}
                    color="secondary"
                    variant="contained"
                    size="large"
                    onClick={toggleDropQuery}
                    spy={true}
                    to="request-for-training-form-section"
                    offset={-TOOL_BAR_HEIGHT}
                    duration={500}
                  >
                    Enquire Now
                  </Button>

                  <FormDialog
                    open={dropQuery}
                    onClose={toggleDropQuery}
                    title="Drop a query"
                  >
                    <DropQueryForm afterSubmit={handlePopupClose} />
                  </FormDialog>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Image
              src="/images/corporate-learning-solution-vector.svg"
              style={{ width: "100%", height: "auto" }}
              width={400}
              height={350}
              quality={70}
              priority={false}
              alt="corporate-learning-solution"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CorporateLearningSolutionSlider;
