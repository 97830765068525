import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";

type SeoProps = {
  title: string;
  description?: string;
};
const Seo: React.FC<SeoProps> = ({ title, description }) => {
  const { asPath } = useRouter();
  return (
    <Head>
      {Boolean(title) && <title>{title}</title>}
      {Boolean(description) && (
        <meta name="description" content={description} />
      )}
      {/*  {Boolean(keywords) && <meta name="keywords" content={keywords} />} */}

      {Boolean(title) && <meta property="og:title" content={title} />}
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        key="ogImage"
        content={`${process.env.NEXT_PUBLIC_APP_URL}/images/logo.webp`}
      />
      <meta
        property="og:url"
        content={process.env.NEXT_PUBLIC_APP_URL + asPath}
      />
      <meta property="og:locale" content="en-US" />
      <meta property="og:site_name" content="spoclearn.com" />
      {Boolean(description) && (
        <meta property="og:description" content={description} />
      )}

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@spoclearn" />
      <meta name="twitter:creator" content="@spoclearn" />
      {Boolean(title) && <meta name="twitter:title" content={title} />}
      {Boolean(description) && (
        <meta name="twitter:description" content={description} />
      )}
      <meta
        name="twitter:image"
        key="twitterImg"
        content={`${process.env.NEXT_PUBLIC_APP_URL}/images/logo.webp`}
      />
      <meta name="twitter:url" content="https://x.com/spoclearn" />
    </Head>
  );
};

export default Seo;
