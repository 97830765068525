import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Image from "next/image";
import ButtonLink from "components/custom/ButtonLink";
const useSpoclernStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
    },
    heroImage: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    heading: {
      "& span": {
        color: theme.palette.secondary.main,
        whiteSpace: "pre",
        marginTop: theme.spacing(1),
        display: "block",
      },
    },
    quateBox: {
      backgroundColor: "#ebebeb",
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(3),
      borderRadius: 6,
    },
  })
);

const ChooseSpoclearn: React.FC<ChooseSpoclearnProps> = ({ content }) => {
  const classes = useSpoclernStyle();
  const { heading, text, quote, image_path } = content;

  return (
    <Box className={classes.root} paddingY={[3, 5, 6, 8]}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={7} md={7} lg={7}>
            <Typography
              variant="h2"
              gutterBottom
              className={classes.heading}
              component="h2"
              dangerouslySetInnerHTML={{ __html: heading }}
            />

            <Typography gutterBottom>{text}</Typography>
            <Box className={classes.quateBox}>
              <Typography variant="caption" component="p">
                {quote}
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              size="large"
              component={ButtonLink}
              href="/about-us"
            >
              Know More
            </Button>
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={5} className={classes.heroImage}>
            <Image
              src={encodeURI(
                process.env.NEXT_PUBLIC_ADMIN_URL +
                  "storage/images/" +
                  image_path
              )}
              width={459}
              height={459}
              style={{ width: "100%", height: "auto" }}
              alt="choose-spoclearn"
              // priority
              loading="lazy"
              blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNUmnGuHgAEdQIJ6vmfKQAAAABJRU5ErkJggg=="
              placeholder="blur"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ChooseSpoclearn;
