import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: "transparent",
      color: theme.palette.common.white,
      border: "none",
    },
    count: {
      marginTop: theme.spacing(2),
      width: 75,
      height: 75,
      borderRadius: 50,
      flex: `0 0 auto`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(10,55,113,0.64)`,
      fontSize: 36,
      fontWeight: 300,
    },
    contents: {},
  })
);
const CorporateLearningSolutionCard: React.FC<
  CorporateLearningSolutionCardProps
> = ({ heading, sub_heading, paragraph, count }) => {
  const classes = useStyle();
  return (
    <Paper square variant="outlined" className={classes.root}>
      <Box className={classes.count}>{count}</Box>
      <Box className={classes.contents} paddingY={2} paddingX={2}>
        <Typography variant="h2" component="h3" gutterBottom>
          {heading}
        </Typography>
        <Typography
          variant="h2"
          component="em"
          gutterBottom
          style={{ fontWeight: 500 }}
        >
          {sub_heading}
        </Typography>
        <Typography paragraph>{paragraph}</Typography>
      </Box>
    </Paper>
  );
};

export default CorporateLearningSolutionCard;
