import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Image from "next/image";
const useStyle = makeStyles(() =>
  createStyles({
    root: {
      // padding: theme.spacing(2),
      minHeight: 160,
      position: "relative",
      boxShadow: "none",
      overflow: "hidden",
    },
  })
);

interface GlobalOrganizationCardProps {
  imageUrl: string;
  alt: string;
}

const GlobalOrganizationCard: React.FC<GlobalOrganizationCardProps> = ({
  imageUrl,
  alt,
}) => {
  const classes = useStyle();
  return (
    <Paper className={classes.root} variant="outlined">
      <Image
        src={imageUrl}
        alt={alt}
        style={{ width: "100%", height: "auto" }}
        width={602}
        height={320}
        quality={90}
        loading="lazy"
      />
    </Paper>
  );
};

export default GlobalOrganizationCard;
