import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import KeyDifferentiatorCard from "../cards/KeyDifferentiatorCard";
import renderHTML from "lib/render-html";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: { paddingRight: 8, alignSelf: "center" },
    heading: {
      "& span": {
        fontWeight: 500,
        display: "block",
        marginTop: theme.spacing(1),
      },
    },
  })
);

const KeyDifferentiators: React.FC<KeyDifferentiatorsProps> = ({ content }) => {
  const classes = useStyles();
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { heading, cards } = content;

  return (
    <Box paddingY={[3, 4, 6, 8]} component="section">
      <Container>
        <Grid container spacing={xs ? 2 : sm ? 3 : 5}>
          <Grid item xs={12} sm={6} md={4} lg={4} className={classes.grid}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.heading}
            >
              {renderHTML(heading)}
            </Typography>
          </Grid>
          {cards?.map((card) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={card.color}>
              <KeyDifferentiatorCard
                color={card.color}
                textContent={card.textContent}
                icon={
                  process.env.NEXT_PUBLIC_ADMIN_URL +
                  "storage/images/" +
                  card.icon
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default KeyDifferentiators;
