import dynamic from "next/dynamic";
import HomeBanner from "components/banners/HomeBanner";
import Counters from "components/common/Counters";
import ChooseSpoclearn from "components/common/ChooseSpoclearn";
import PopularCategories from "components/common/PopularCategories";
import Seo from "components/Seo";
import KeyDifferentiators from "components/common/KeyDifferentiators";
import Banner from "components/common/Banner";
import PopularCourseSlider from "components/sliders/PopularCourseSlider";
import TrendingCourses from "components/common/TrendingCourses";
import GlobalOrganizationSlider from "components/sliders/GlobalOrganizationSlider";
import TrainingDeliveryModeSlider from "components/sliders/TrainingDeliveryModeSlider";
import CorporateLearningSolutionSlider from "components/sliders/CorporateLearningSolutionSlider";
import OurAccreditationsSlider from "components/sliders/OurAccreditationsSlider";
//#########################

import { initializeApollo } from "src/utils/apolloClient";
import HOME_PAGE_QUERY from "src/graphql/query/homePageQuery";

import LoadComponent from "src/utils/loadComponents";
import mergeContents from "src/utils/mergeContents";
import AlternateLnks from "components/common/AlternateLnks";
import { GetServerSideProps } from "next";
import GeoLocation from "components/hooks/GeoLocation";
const OurBlogs = dynamic(() => import("components/blog/OurBlogs"), {
  ssr: false,
});

//#############################
const Components = {
  homebanner: HomeBanner,
  counters: Counters,
  choosespoclearn: ChooseSpoclearn,
  ouraccreditationsslider: OurAccreditationsSlider,
  ourblogs: OurBlogs,
  popularcategories: PopularCategories,
  keydifferentiators: KeyDifferentiators,
  banner: Banner,
  popularcourseslider: PopularCourseSlider,
  trendingcourses: TrendingCourses,
  globalorganizationslider: GlobalOrganizationSlider,
  trainingdeliverymodeslider: TrainingDeliveryModeSlider,
  corporatelearningsolutionslider: CorporateLearningSolutionSlider,
};

const Home = (props) => {
  const { title, meta_description, meta_keyword, pageContents } =
    props?.page_by_type_slug;
  const { countries } = props;

  const { popularCourses, popularCategories } = props;
  const needToCombined = {
    PopularCategories: {
      content: {
        heading: "Discover our Popular Categories",
        categories: popularCategories,
      },
    },
    PopularCourseSlider: {
      content: {
        heading: "Discover our Popular Courses",
        courses: popularCourses,
      },
    },
  };
  const mergedData = mergeContents(pageContents, needToCombined);

  return (
    <>
      <Seo
        title={title}
        description={meta_description}
        keywords={meta_keyword}
      />
      <AlternateLnks countries={countries} />
      {mergedData?.map((item) => LoadComponent(item, Components))}
    </>
  );
};
export default Home;

export const getServerSideProps: GetServerSideProps = async (context) => {
  GeoLocation(context);

  const apolloClient = initializeApollo();
  const { data } = await apolloClient.query({
    query: HOME_PAGE_QUERY,
    variables: {
      pageType: "HOME",
      slug: "//",
      countryCode: "us",
      popularCourseType: "GENERIC COURSE",
      popularCategoryType: "GENERIC CATEGORY",
    },
  });

  return {
    props: data,
  };
};
