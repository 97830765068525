import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { People, Star } from "@material-ui/icons";
import renderHTML from "lib/render-html";
// import ButtonLink from "components/custom/ButtonLink";
import Image from "next/image";
import { useRouter } from "next/router";
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: "background-color .2s",
      padding: theme.spacing(2),
      minHeight: 300,
      boxShadow: "0px 8px 16px 0px rgb(0 0 0 / 15%)",
      display: "flex",
      flexDirection: "column",

      "& .icon": {
        width: 60,
        height: 60,
        borderRadius: 50,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[300],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      "& .category": {
        backgroundColor: theme.palette.primary.main,
        width: "fit-content",
        padding: theme.spacing(0.5, 1.5),
        borderRadius: 10,
        // color: theme.palette.common.white,
      },
      "& .reviewLearners": {
        paddingTop: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .actions": {
        display: "none",
        backgroundColor: theme.palette.common.white,
        margin: "0 auto",
        marginTop: theme.spacing(2),
      },

      "&:hover": {
        // background: `linear-gradient(to bottom, #6AD2FC , #599BFC)`,
        backgroundColor: theme.palette.primary.main,

        "& .heading": {
          color: theme.palette.common.white,
        },
        "& .icon": {
          backgroundColor: theme.palette.common.white,
        },
        "& .category": {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main,
        },

        "& .divider": {
          display: "none",
        },
        "& .reviewLearners": {
          display: "none",
        },
        "& .actions": {
          display: "block",
        },
      },
    },
  })
);
const PopularCourseCardComponent: React.FC<PopularCourseCardProps> = (
  props
) => {
  const classes = useStyle();
  const {
    service_name,
    category_name,
    course_rating,
    course_learners,
    menu_icon_media_path,
    category_slug_country,
    category_slug_generic,
    page_slug,
  } = props;

  const { country } = useRouter().query;
  return (
    <Paper className={classes.root} variant="outlined">
      <div className="icon">
        {menu_icon_media_path && (
          <Image
            src={
              process.env.NEXT_PUBLIC_ADMIN_URL +
              "storage/images/" +
              menu_icon_media_path
            }
            width={60}
            height={60}
            alt={service_name}
            loading="lazy"
          />
        )}
      </div>
      <Box paddingY={2} flex={1}>
        <Typography variant="h4" paragraph className="heading">
          {renderHTML(service_name)}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          gutterBottom
          className="category"
        >
          {renderHTML(category_name)}
        </Typography>
      </Box>
      <Divider className="divider" />
      <div className="reviewLearners">
        <div>
          <Star fontSize="small" />
          <Typography variant="caption" component="p">
            {course_rating ? course_rating : 0} Reviews
          </Typography>
        </div>
        <div>
          <People fontSize="small" />
          <Typography variant="caption" component="p">
            {course_learners ? course_learners : 0} Learners
          </Typography>
        </div>
      </div>
      <Button
        size="large"
        variant="outlined"
        color="primary"
        tabIndex="-1"
        className="actions"
        href={
          country
            ? `/${country}/courses/${category_slug_country}/${page_slug}/`
            : `/courses/${category_slug_generic}/${page_slug}/`
        }
        // component={ButtonLink}
      >
        Know More
      </Button>
    </Paper>
  );
};
const PopularCourseCard = React.memo(PopularCourseCardComponent);
export default PopularCourseCard;
